import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import GraphsPage from 'js/containers/GraphsPage';
import AlarmsPage from 'js/containers/AlarmsPage';
import LogPage from 'js/containers/LogPage';
import PowerControlPage from 'js/containers/PowerControlPage';
import SignalProvidersPage from 'js/containers/SignalProvidersPage';
import DashboardPage from 'js/components/DashboardPage/DashboardPage';
import PersonalDashboardPage from 'js/components/DashboardPage/PersonalDashboardPage';
import { UserDashboardsSubPage } from 'js/utils/routeConstants';
import Jobs from 'js/components/Jobs/Jobs';
import AlarmsPageV2 from '../../containers/AlarmsPageV2';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

type Section = {
  route: string;
  title: string;
  icon: JSX.Element;
  renderContent: ({
    onTitleChanged,
    selectedNode
  }: {
    onTitleChanged: (title: string[]) => void;
    selectedNode?: SingleGridNode;
  }) => JSX.Element;
};

const sections: Section[] = [
  {
    route: UserDashboardsSubPage,
    title: T.location.tabs.dashboard,
    icon: <Icons.Favorite />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <PersonalDashboardPage onTitleChanged={onTitleChanged} />
  },
  {
    route: 'dashboard',
    title: T.location.tabs.dashboard,
    icon: <Icons.Dashboard />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <DashboardPage onTitleChanged={onTitleChanged} />
  },
  {
    route: 'jobs',
    title: T.location.tabs.jobs,
    icon: <Icons.DataJobs />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <Jobs onTitleChanged={onTitleChanged} />
  },
  {
    route: 'graphs',
    title: T.location.tabs.graphs,
    icon: <Icons.BarGraph />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <GraphsPage onTitleChanged={onTitleChanged} />
  },
  {
    route: 'tools',
    title: T.location.tabs.tools,
    icon: <Icons.Tool />,
    renderContent: ({
      onTitleChanged,
      selectedNode
    }: {
      onTitleChanged: (title: string[]) => void;
      selectedNode: SingleGridNode;
    }) => (
      <PowerControlPage
        onTitleChanged={onTitleChanged}
        selectedNode={selectedNode}
      />
    )
  },
  {
    route: 'alarms',
    title: T.location.tabs.alarms,
    icon: <Icons.AlarmBell />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <AlarmsPage onTitleChanged={onTitleChanged} />
  },
  {
    route: 'newalarms',
    title: T.location.tabs.alarms + ' V2',
    icon: <Icons.AlarmBell />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <AlarmsPageV2 onTitleChanged={onTitleChanged} />
  },
  {
    route: 'signalproviders',
    title: T.location.tabs.signalproviders,
    icon: <Icons.Signal />,
    renderContent: ({
      onTitleChanged,
      selectedNode
    }: {
      onTitleChanged: (title: string[]) => void;
      selectedNode: SingleGridNode;
    }) => (
      <SignalProvidersPage
        onTitleChanged={onTitleChanged}
        selectedNode={selectedNode}
      />
    )
  },
  {
    route: 'logs',
    title: T.location.tabs.logs,
    icon: <Icons.Logs />,
    renderContent: ({
      onTitleChanged
    }: {
      onTitleChanged: (title: string[]) => void;
    }) => <LogPage onTitleChanged={onTitleChanged} />
  }
];

export default sections;
