import React from 'react';
import AlarmSeverityCircle from 'ecto-common/lib/Alarms/AlarmSeverityCircle';
import { AlarmSeverity } from 'ecto-common/lib/constants';

const SeverityColumn = ({ severity }: { severity?: number }) => {
  let translatedSeverity = AlarmSeverity.LOW;

  if (severity >= 600) {
    translatedSeverity = AlarmSeverity.HIGH;
  } else if (severity >= 400) {
    translatedSeverity = AlarmSeverity.MEDIUM;
  } else {
    translatedSeverity = AlarmSeverity.LOW;
  }

  return <AlarmSeverityCircle severity={translatedSeverity} />;
};

export default SeverityColumn;
