import React from 'react';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';

const SignalNameColumn = ({
  signalName,
  signalDescription
}: {
  signalName: string;
  signalDescription: string;
}) => {
  return <TableColumn title={signalName} subtitle={signalDescription} />;
};
export default SignalNameColumn;
