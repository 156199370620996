import React, { useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { AlarmModel } from 'ecto-common/lib/API/AlarmsAPIGen';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import styles from '../AlarmView.module.css';
import classNames from 'classnames';

const ActiveAlarmColumn = ({
  alarm,
  showAlarmHistory
}: {
  alarm: AlarmModel;
  showAlarmHistory: (sourceUri: string, occuredAt?: string) => void;
}) => {
  const onActiveAlarmClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      showAlarmHistory(alarm.sourceUri, null);
    },
    [showAlarmHistory, alarm.sourceUri]
  );
  return (
    <TableColumn
      title={
        <span className={classNames(alarm.isActive && styles.active)}>
          {alarm.isActive ? T.alarms.active : T.alarms.inactive}
        </span>
      }
      subtitle={
        (alarm.countActiveStatesSinceLastAcknowledgment ?? 0) !== 0 && (
          <a href="#" onClick={onActiveAlarmClick}>
            {T.format(
              T.alarms.numberofactiveformat,
              alarm.countActiveStatesSinceLastAcknowledgment
            )}
          </a>
        )
      }
    />
  );
};
export default ActiveAlarmColumn;
