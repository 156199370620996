import _ from 'lodash';
import React, { useContext, useMemo } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {
  SecosimAreaAggregateGraphData,
  SecosimAreaGraphData,
  SecosimColumnGraphData,
  SecosimForm,
  SecosimResult,
  SecosimSankeyGraphData
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

const domProps = {
  style: {
    height: '100%'
  }
};

import HighchartsSankey from 'highcharts/modules/sankey';
import { useQueries } from '@tanstack/react-query';
import EctoplannerAPIGen from 'ecto-common/lib/API/EctoplannerAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { parseTimeSeriesFile } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculationUtil';
import { colorTable } from 'ecto-common/lib/SignalSelector/StockChart.config';
import SecosimResultWarnings from 'js/components/Ectoplanner/Secosim/SecosimResultWarnings';
import TreeView, { isTreeNodeFolder } from 'ecto-common/lib/TreeView/TreeView';
import {
  TreeViewColumnType,
  TreeViewNodeType
} from 'ecto-common/lib/TreeView/TreeViewNode';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { SecosimResultGraphType } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import {
  ectoplannerDayNames,
  ectoplannerHourNames,
  ectoplannerMonthNames
} from '../EctoplannerGraphBrowser/EctoplannerGraphBrowserTypes';
import T from 'ecto-common/lib/lang/Language';
import Notice from 'ecto-common/lib/Notice/Notice';
HighchartsSankey(Highcharts);

const SecosimGraph = ({ options }: { options: Highcharts.Options }) => {
  return (
    <>
      {options && (
        <PlainBox
          style={{
            marginBottom: dimensions.standardMargin,
            height: 440,
            flexShrink: 0
          }}
        >
          <div>
            <HighchartsReact
              options={options}
              highcharts={Highcharts}
              containerProps={domProps}
            />
          </div>
        </PlainBox>
      )}
    </>
  );
};

const SecosimAreaAggregateGraph = ({
  figure,
  title,
  subtitle
}: {
  figure: SecosimAreaAggregateGraphData;
  title: string;
  subtitle: string;
}) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'area'
    },
    title: {
      text: title
    },
    subtitle: {
      text: subtitle
    },
    xAxis: {
      categories: figure.x_categories
    },
    yAxis: {
      title: {
        text: figure.y_label
      }
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666'
        }
      }
    },
    credits: {
      enabled: false
    },
    series: figure.series_values.map((values, idx) => ({
      name: figure.series_labels[idx],
      data: values,
      type: 'area'
    }))
  };

  return <SecosimGraph options={options} />;
};
const SecosimAreaGraph = ({
  figure,
  buildId,
  title,
  subtitle
}: {
  figure: SecosimAreaGraphData;
  buildId: string;
  title: string;
  subtitle: string;
}) => {
  const { contextSettings } = useContext(TenantContext);

  const queries = useQueries({
    queries: _.map(figure.series_ids, (seriesId) => {
      return {
        queryKey: ['ectoplanner', 'getKPIData', buildId, seriesId],
        queryFn: ({ signal }: { signal: AbortSignal }) => {
          return EctoplannerAPIGen.EctoGridBuilds.timeseriesDetail.promise(
            contextSettings,
            {
              buildId: buildId,
              filename: seriesId
            },
            signal
          );
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
      };
    })
  });
  const partiallyLoaded = _.some(queries, (query) => query.isLoading);

  const series = useMemo(() => {
    if (partiallyLoaded) {
      return [];
    }

    return queries.map((query) => {
      if (query.data != null) {
        const data = query.data as string;
        return parseTimeSeriesFile(data);
      }

      return null;
    });
  }, [queries, partiallyLoaded]);

  const seriesLength = series?.[0]?.length ?? 0;

  let xCategories: string[] = null;

  if (seriesLength === 8760) {
    xCategories = ectoplannerHourNames;
  } else if (seriesLength === 365) {
    xCategories = ectoplannerDayNames;
  } else if (seriesLength === 12) {
    xCategories = ectoplannerMonthNames;
  }

  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      zooming: {
        type: 'x'
      }
    },
    title: {
      text: title
    },
    subtitle: {
      text: subtitle
    },
    xAxis: {
      categories: xCategories
    },
    yAxis: {
      min: figure.min,
      max: figure.max,
      title: {
        text: figure.y_label
      }
    },
    plotOptions: {
      area: {
        pointStart: 1940,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    series: partiallyLoaded
      ? []
      : figure.series_labels.map((label, idx) => {
          return {
            type: 'line',
            name: label,
            data: series[idx],
            color: colorTable[idx],
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' (' + figure.y_label + ')'
            }
          };
        })
  };

  return <SecosimGraph options={options} />;
};

const SecosimColumnGraph = ({
  figure,
  title,
  subtitle
}: {
  figure: SecosimColumnGraphData;
  title: string;
  subtitle: string;
}) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: title
    },
    subtitle: {
      text: subtitle
    },
    xAxis: {
      categories: figure.x_categories,
      crosshair: true
    },
    navigator: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    yAxis: [
      {
        title: {
          text: figure.y_label
        }
      },
      {
        title: {
          text: figure.y_label2
        },
        opposite: true
      }
    ],

    plotOptions: {
      column: {
        stacking: null
      }
    },
    series: figure.data.map((data, idx) => {
      return {
        type: 'column',
        name: data.name,
        data: data.data,
        yAxis: data.second_y ? 1 : 0,
        color: colorTable[idx],
        tooltip: {
          valueDecimals: 2,
          valueSuffix:
            ' (' + (data.second_y ? figure.y_label2 : figure.y_label) + ')'
        }
      };
    })
  };

  return <SecosimGraph options={options} />;
};

const SecosimSankeyGraph = ({
  figure,
  title,
  subtitle
}: {
  figure: SecosimSankeyGraphData;
  title: string;
  subtitle: string;
}) => {
  const sankeyOptions: Highcharts.Options = useMemo(() => {
    return {
      title: {
        text: title
      },
      subtitle: {
        text: subtitle
      },
      tooltip: {
        headerFormat: null,
        pointFormat: figure.pointFormat[0],
        nodeFormat: figure.nodeFormat
      },
      credits: {
        enabled: false
      },
      series: [
        {
          keys: figure.keys,
          nodes: figure.nodes,
          data: figure.data,
          type: 'sankey',
          dataLabels: {
            color: 'white',
            style: {
              textOutline: '1px solid #333'
            }
          }
        }
      ]
    } as const;
  }, [
    figure.data,
    figure.keys,
    figure.nodeFormat,
    figure.nodes,
    figure.pointFormat,
    subtitle,
    title
  ]);

  return <SecosimGraph options={sankeyOptions} />;
};

type SecosimTreeViewNodeType = TreeViewNodeType & {
  subtitle: string;
  children: SecosimTreeViewNodeType[];
  figure: SecosimResultGraphType;
};

const columns: TreeViewColumnType<SecosimTreeViewNodeType>[] = [
  {
    dataFormatter: (node, isOpen) => {
      if (isTreeNodeFolder(node)) {
        if (isOpen) {
          return <Icons.FolderOpen />;
        }

        return <Icons.FolderClosed />;
      }

      return null;
    }
  },
  {
    dataFormatter: (node, _isOpen, isSelected) => {
      return (
        <TableColumn
          title={node.name}
          subtitle={node.subtitle}
          active={isSelected}
        />
      );
    },
    flexGrow: 1
  }
];

function getSubtitleForFigure(figure: SecosimResultGraphType) {
  let subtitle = '';

  const appendIfExists = (str: string) => {
    if (str != null) {
      if (subtitle !== '') {
        subtitle += ' - ';
      }

      subtitle += str;
    }
  };

  appendIfExists(figure.asset_name);

  if (figure.is_coordinated !== undefined) {
    appendIfExists(
      figure.is_coordinated
        ? T.ectoplanner.secosim.columns.coordinated
        : T.ectoplanner.secosim.columns.noncoordinated
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendIfExists((figure as unknown as any).y_label);

  return subtitle;
}

const findNode = (
  nodes: SecosimTreeViewNodeType[],
  callback: (node: SecosimTreeViewNodeType) => boolean
): SecosimTreeViewNodeType | null => {
  for (const node of nodes) {
    if (callback(node)) {
      return node;
    }

    const childNode = findNode(node.children, callback);
    if (childNode != null) {
      return childNode;
    }
  }

  return null;
};

const SecosimKPIGraph = ({
  title,
  subtitle,
  figure,
  buildId
}: {
  buildId: string;
  figure: SecosimResultGraphType;
  title: string;
  subtitle: string;
}) => {
  switch (figure.figure_type) {
    case 'AreaAggregate':
      return (
        <SecosimAreaAggregateGraph
          figure={figure}
          title={title}
          subtitle={subtitle}
        />
      );
    case 'Sankey':
      return (
        <SecosimSankeyGraph figure={figure} title={title} subtitle={subtitle} />
      );
    case 'Area':
      return (
        <SecosimAreaGraph
          buildId={buildId}
          figure={figure}
          title={title}
          subtitle={subtitle}
        />
      );
    case 'Column':
      return (
        <SecosimColumnGraph figure={figure} title={title} subtitle={subtitle} />
      );
    default:
      return null;
  }
};

const SecosimKPIGraphs = ({
  result,
  buildId,
  form
}: {
  result: SecosimResult;
  buildId: string;
  form: SecosimForm;
}) => {
  _.noop(buildId);
  _.noop(form);

  const [selectedId, setSelectedId] = useSearchParamState('graph', null);

  const treeViewNodes = useMemo(() => {
    const buildingNodes = _.groupBy(
      result?.figures,
      (figure) => figure.building_name
    );

    const nodes: SecosimTreeViewNodeType[] = Object.values(buildingNodes)
      .map((figures) => {
        const buildingName =
          figures[0].building_name ?? T.ectoplanner.sections.grid;
        return {
          name: buildingName,
          id: buildingName,
          path: buildingName,
          subtitle: null,
          figure: null,
          children: figures.map((figure, idx) => {
            const id =
              (figure.building_name ?? T.ectoplanner.sections.grid) + '-' + idx;

            let name = figure.title.replaceAll('Building ', '');
            if (figure.type != null) {
              name += ' - ' + figure.type;
            }

            return {
              name,
              id,
              path: id,
              children: [],
              subtitle: getSubtitleForFigure(figure),
              figure: figure
            };
          })
        };
      })
      .sort((a, b) => {
        // Ensure nodes starting with `T.ectoplanner.sections.grid` come first
        const startsWithGridA = a.name.startsWith(T.ectoplanner.sections.grid);
        const startsWithGridB = b.name.startsWith(T.ectoplanner.sections.grid);

        if (startsWithGridA && !startsWithGridB) return -1;
        if (!startsWithGridA && startsWithGridB) return 1;
        return 0; // Maintain original order if both or neither match
      });

    return nodes;
  }, [result]);

  const selectedNode =
    findNode(treeViewNodes, (node) => {
      return node.id === selectedId;
    }) ??
    findNode(treeViewNodes, (node) => {
      return (
        node.figure?.type === 'OperatingCosts' &&
        node.figure?.building_name === 'Grid'
      );
    }) ??
    findNode(treeViewNodes, (node) => {
      return node.figure?.type === 'OperatingCosts';
    });

  return (
    <>
      <Notice>{T.ectoplanner.secosim.results.kpigraphshelptext}</Notice>

      <SecosimResultWarnings warnings={result.warnings} />

      <div style={{ display: 'flex', marginTop: dimensions.standardMargin }}>
        <div
          style={{
            maxWidth: 400,
            minWidth: 400,
            marginRight: dimensions.standardMargin
          }}
        >
          <TreeView
            compact
            columns={columns}
            nodes={treeViewNodes}
            onClickNode={(node) => {
              setSelectedId(node.id);
            }}
            selectedNodes={{ [selectedNode?.id]: true }}
            selectFolder
          />
        </div>
        <div style={{ flexGrow: 1, width: 1 }}>
          {selectedNode?.figure && (
            <SecosimKPIGraph
              title={selectedNode.name}
              subtitle={selectedNode.subtitle}
              key={selectedNode.id}
              figure={selectedNode.figure}
              buildId={buildId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(SecosimKPIGraphs);
