import React, { useCallback, useContext, useMemo } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import { YAxisOptions } from 'highcharts';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  createPointFormatter,
  yAxisFormatter
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import APIGen, {
  AggregationType,
  SamplingInterval,
  SignalProviderTelemetryResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useNavigate } from 'react-router-dom';
import { getJobsUrl } from 'js/utils/routeConstants';
import JobsAPIGenV2, { RunTimeseries } from 'ecto-common/lib/API/JobsAPIGenV2';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import moment from 'moment';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

function createJobsCompareChartConfig({
  signals,
  signalValues,
  runTimeSeries,
  signalUnitTypesMap,
  signalTypesMap
}: {
  signals: SignalProviderSignalResponseModel[];
  signalValues: SignalProviderTelemetryResponseModel[];
  runTimeSeries: RunTimeseries[];
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
}): Highcharts.Options {
  if (signals.length === 0) {
    return {
      series: []
    };
  }

  const allUnits = _.uniq(
    _.map(signals, (signal) =>
      getSignalTypeUnit(signal.signalTypeId, signalTypesMap, signalUnitTypesMap)
    )
  );

  const yAxis: YAxisOptions[] = _.map(allUnits, (item, idx) => ({
    id: item,
    title: {
      text: item
    },
    labels: {
      formatter: yAxisFormatter
    },
    showEmpty: true,
    opposite: idx % 2 === 1
  }));

  return {
    yAxis,
    series: [
      ..._.map(signalValues, (item) => {
        const signal = _.find(signals, (x) => x.signalId === item.signalId);
        const unit = getSignalTypeUnit(
          signal?.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        );

        return {
          type: 'line',
          name: signal?.name ?? '',
          yAxis: unit,
          data: item.signals.map((value) => [
            new Date(value.time).getTime(),
            value.value
          ]),
          tooltip: {
            pointFormatter: createPointFormatter(unit),
            valueDecimals: numDecimalsForUnit(unit),
            // Don't show unit if its null
            valueSuffix: unit ? ' ' + unit : ''
          }
        } as const;
      }),
      ..._.map(runTimeSeries, (item) => {
        const signal = _.find(signals, (x) => x.signalId === item.dataPointId);
        const unit = getSignalTypeUnit(
          signal?.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        );

        return {
          type: 'line',
          yAxis: unit,
          name: T.format(T.jobs.predictedformat, signal?.name ?? '').join(''),
          data: item.values.map((value) => [
            new Date(value.dateTime).getTime(),
            value.floatValue
          ]),
          tooltip: {
            pointFormatter: createPointFormatter(unit),
            valueDecimals: numDecimalsForUnit(unit),
            // Don't show unit if its null
            valueSuffix: unit ? ' ' + unit : ''
          }
        } as const;
      })
    ]
  };
}

const JobsCompareGraphsModal = ({
  nodeId,
  jobId,
  fileName
}: {
  nodeId: string;
  jobId: string;
  fileName: string;
}) => {
  const navigate = useNavigate();

  const { tenantId } = useContext(TenantContext);

  const onModalClose = useCallback(() => {
    navigate(getJobsUrl(tenantId, nodeId, jobId));
  }, [jobId, navigate, nodeId, tenantId]);

  const dataQuery = JobsAPIGenV2.Jobs.getCurrentJob.useQuery(
    {
      jobId,
      nodeId
    },
    {
      enabled: nodeId != null && jobId != null
    }
  );

  const runsDataQuery = JobsAPIGenV2.Jobs.downloadRun.useQuery(
    {
      fileName,
      jobId,
      nodeId
    },
    {
      enabled: fileName != null && jobId != null
    }
  );

  const signalInfoQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds: dataQuery.data?.outputDataPointIds
    },
    {
      enabled: dataQuery.data != null
    }
  );

  const startTime = runsDataQuery.data?.firstValueDateTime;
  const endTime = runsDataQuery.data?.lastValueDateTime;

  const signalValuesQuery = APIGen.Signals.getSignalValuesByTimeRange.useQuery(
    {
      StartDate: startTime,
      EndDate: endTime,
      Aggregation: AggregationType.None,
      SamplingInterval: SamplingInterval.Raw,
      SignalIds: dataQuery.data?.outputDataPointIds,
      IsInclusive: true
    },
    {
      enabled:
        runsDataQuery.data != null && startTime != null && endTime != null
    }
  );

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const signals = useMemo(() => {
    if (signalInfoQuery.data == null) {
      return [];
    }

    const providers = signalInfoQuery.data;
    const outputIds = dataQuery.data?.outputDataPointIds ?? [];
    const allSignals = _.flatMap(providers, (provider) => provider.signals);
    return _.filter(allSignals, (signal) =>
      outputIds.includes(signal.signalId)
    );
  }, [dataQuery.data?.outputDataPointIds, signalInfoQuery.data]);

  const config = useMemo(() => {
    return createJobsCompareChartConfig({
      signals,
      signalValues: signalValuesQuery.data ?? [],
      runTimeSeries: runsDataQuery.data?.timeseriesData ?? [],
      signalTypesMap,
      signalUnitTypesMap
    });
  }, [
    runsDataQuery.data?.timeseriesData,
    signalTypesMap,
    signalUnitTypesMap,
    signalValuesQuery.data,
    signals
  ]);

  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const nodeName = nodeMap[nodeId]?.name ?? '';

  const runTime =
    runsDataQuery.data?.runDateTime != null
      ? moment(runsDataQuery.data?.runDateTime).format(
          getDefaultDateTimeFormat(TimeFormats.LONG_TIME)
        )
      : '';

  const hasError =
    runsDataQuery.isError ||
    signalValuesQuery.isError ||
    signalInfoQuery.isError ||
    dataQuery.isError;

  return (
    <ActionModal
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Graph}
      disableCancel
      actionText={T.common.done}
      isOpen={fileName != null}
      isLoading={
        runsDataQuery.isLoading ||
        signalValuesQuery.isLoading ||
        dataQuery.isLoading ||
        signalInfoQuery.isLoading
      }
      title={T.format(
        T.jobs.titleformat,
        nodeName,
        dataQuery.data?.jobName ?? '',
        runTime
      )}
      fullScreen
    >
      {!hasError && config.series.length > 0 && <StockChart config={config} />}
      {hasError && <ErrorNotice>{T.common.unknownerror} </ErrorNotice>}
    </ActionModal>
  );
};

export default JobsCompareGraphsModal;
