import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { performAsyncRequestWithStateChanges } from 'ecto-common/lib/API/API';
import {
  getInitialReqState,
  initialReqState,
  RequestStatusRawProp,
  updateReqState
} from 'ecto-common/lib/utils/requestStatus';
import { matchPath } from 'react-router-dom';
import {
  baseLocationUrlPath,
  NodeParams
} from 'ecto-common/lib/utils/locationPathUtils';
import { LOCATION_CHANGE } from 'ecto-common/lib/actions/actionTypes';
import APIGen, {
  FullSignalProviderResponseModel
} from 'ecto-common/lib/API/APIGen';
import { CommonDispatch } from '../../reducers/storeCommon';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { AnyAction } from '@reduxjs/toolkit';

const RESET_REQ_STATES = 'SIGNALS/RESET_REQ_STATES';
const GET_PROVIDERS_REQ_STATE = 'SIGNALS/GET_PROVIDERS_REQ_STATE';

type SignalsReducerTypes = {
  providersReqState: RequestStatusRawProp<FullSignalProviderResponseModel[]>;
  nodeId: string;
};

const initialState: SignalsReducerTypes = {
  providersReqState: getInitialReqState<FullSignalProviderResponseModel[]>(),
  nodeId: null
};

export default createReducer(initialState, {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [LOCATION_CHANGE]: (state: SignalsReducerTypes, pathName: any) => {
    const match = matchPath<NodeParams, string>(baseLocationUrlPath, pathName);

    if (match) {
      const { nodeId } = match.params;

      if (nodeId !== state.nodeId) {
        return state;
      }

      return { ...initialState, nodeId };
    }

    return initialState;
  },
  [RESET_REQ_STATES]: (state: SignalsReducerTypes) => {
    return {
      ...state,
      providersReqState: initialReqState
    };
  },
  [GET_PROVIDERS_REQ_STATE]: (
    state: SignalsReducerTypes,
    action: AnyAction
  ) => {
    return {
      ...state,
      providersReqState: updateReqState(state.providersReqState, action)
    };
  }
});

let lastAbortController: AbortController = null;

const _getProviders = (
  contextSettings: ApiContextSettings,
  node: SingleGridNode
) => {
  const requestName = GET_PROVIDERS_REQ_STATE;

  return async (dispatch: CommonDispatch) => {
    if (lastAbortController != null) {
      lastAbortController.abort();
    }

    lastAbortController = new AbortController();
    if (node == null) {
      return;
    }

    const nodeIds: string[] = (node.equipments ?? []).map(
      (equipment) => equipment.equipmentId
    );
    nodeIds.push(node.nodeId);
    const providerRequest = APIGen.Signals.getSignalsByNode.promise(
      contextSettings,
      {
        signalProviderTypes: [],
        nodesIds: nodeIds
      },
      lastAbortController.signal
    );

    await performAsyncRequestWithStateChanges(
      dispatch,
      providerRequest,
      requestName
    );
  };
};

export const SignalsActions = {
  cancelRequests: () => {
    return () => {
      lastAbortController?.abort();
      lastAbortController = null;
    };
  },
  resetRequests: () => ({
    type: RESET_REQ_STATES
  }),
  getProviders: (contextSettings: ApiContextSettings, node: SingleGridNode) => {
    return _getProviders(contextSettings, node);
  }
};
