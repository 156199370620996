import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { useEffect } from 'react';
import _ from 'lodash';

const usePageTitleCallback = ({
  mainTitle,
  subTitle,
  onTitleChanged
}: {
  mainTitle: string;
  subTitle: string;
  onTitleChanged: (title: string[]) => void;
}) => {
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const equipmentId = useCommonSelector((state) => state.general.equipmentId);

  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);

  useEffect(() => {
    const node = getNodeFromMap(nodeMap, nodeId);
    const equipment = getNodeFromMap(equipmentMap, equipmentId);
    onTitleChanged?.(
      _.compact([equipment?.name ?? node?.name, subTitle, mainTitle])
    );
  }, [
    onTitleChanged,
    mainTitle,
    subTitle,
    nodeMap,
    nodeId,
    equipmentMap,
    equipmentId
  ]);
};
export default usePageTitleCallback;
