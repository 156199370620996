import React from 'react';
import AlarmView from 'ecto-common/lib/Alarms/AlarmView';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import T from 'ecto-common/lib/lang/Language';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const AlarmsPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const nodeId = useOperatorSelector((state) => state.general.nodeId);
  usePageTitleCallback({
    mainTitle: T.alarms.title,
    subTitle: '',
    onTitleChanged
  });
  return <AlarmView nodeId={nodeId} />;
};

export default AlarmsPage;
