import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import styles from './EctoplannerEditGraphDialog.module.css';
import { EctoplannerTimeSeries } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import T from 'ecto-common/lib/lang/Language';
import {
  EctoplannerAggregationOptions,
  EctoplannerGraphTypeOptions
} from './EctoplannerGraphBrowserTypes';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import {
  BuildGraphAggregation,
  BuildGraphResponse,
  BuildGraphSeries,
  BuildGraphType
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import TreeView from 'ecto-common/lib/TreeView/TreeView';
import {
  TimeSeriesCategory,
  useTimeseriesTreeViewNodes
} from 'js/components/Ectoplanner/EctoplannerUtils';

export const translateEctoplannerUnit = (unit: string): React.ReactNode => {
  if (unit == null || unit === '') {
    return '';
  }

  const unitLowercase = (unit ?? '').toLowerCase();

  return (
    <>
      (
      {T.ectoplanner.units[unitLowercase as keyof typeof T.ectoplanner.units] ??
        unit}
      )
    </>
  );
};

type EctoplannerEditGraphEnvironment = {
  seriesById: Record<string, EctoplannerTimeSeries>;
};

type EctoplannerEditGraphDialogProps = {
  isOpen: boolean;
  onModalClose: () => void;
  timeseries: EctoplannerTimeSeries[];
  currentCollection: BuildGraphResponse;
  setCurrentCollection: Dispatch<SetStateAction<BuildGraphResponse>>;
  seriesById: Record<string, EctoplannerTimeSeries>;
};

type TimeSeriesCategoryAndColumns = TimeSeriesCategory & {
  columns: DataTableColumnProps<EctoplannerTimeSeries>[];
};

export const TimeseriesTable = ({
  timeseries,
  parentName,
  onAddTimeSeries,
  selectedSeries
}: {
  timeseries: EctoplannerTimeSeries[];
  parentName: string;
  selectedSeries: BuildGraphSeries[];
  onAddTimeSeries(series: EctoplannerTimeSeries): void;
}) => {
  const subcategories: TimeSeriesCategoryAndColumns[] = useMemo(() => {
    const groups = _.groupBy(timeseries, (item) => {
      if (item.is_coordinated !== undefined) {
        return item.is_coordinated
          ? T.ectoplanner.secosim.columns.coordinated
          : T.ectoplanner.secosim.columns.noncoordinated;
      }

      return item.subcategory;
    });

    return sortByLocaleCompare(Object.entries(groups), '[0]').map(
      ([name, subtimeseries]): TimeSeriesCategoryAndColumns => {
        const safeName =
          name === 'undefined' ? T.ectoplanner.graphs.allsignals : name;
        const columns: DataTableColumnProps<EctoplannerTimeSeries>[] = [
          {
            dataKey: 'name',
            label: <TableColumn title={safeName} subtitle={parentName} />,
            dataFormatter: (seriesName, item) => {
              const formattedUnit = translateEctoplannerUnit(item.unit);
              const type = item.type ?? '';

              let suffix = '';
              if (item.asset_name != null || item.building_name != null) {
                suffix = ' - ' + item.category;
              }

              return (
                <TableColumn
                  icon={<Icons.Signal />}
                  title={
                    <>
                      {seriesName} {formattedUnit} - {type}
                      {suffix}
                    </>
                  }
                  subtitle={item.description}
                />
              );
            }
          },
          ...standardColumns<EctoplannerTimeSeries>({
            onAdd(item) {
              onAddTimeSeries(item);
            },
            shouldDisableAdd(item) {
              return selectedSeries.some((x) => x.id === item.id);
            }
          })
        ];

        return {
          name: safeName,
          timeseries: subtimeseries,
          columns
        };
      }
    );
  }, [onAddTimeSeries, parentName, selectedSeries, timeseries]);

  return (
    <>
      {subcategories.map((subcategory) => (
        <DataTable<EctoplannerTimeSeries>
          key={subcategory.name}
          data={subcategory.timeseries}
          columns={subcategory.columns}
          inline
        />
      ))}
    </>
  );
};

type SelectedSignal = {
  series: EctoplannerTimeSeries;
  reference: BuildGraphSeries;
};

const sections: ModelFormSectionType<BuildGraphResponse>[] = [
  {
    lines: [
      {
        models: [
          {
            modelType: ModelType.TEXT,
            key: (input) => input.name,
            label: T.common.name
          },
          {
            modelType: ModelType.OPTIONS,
            key: (input) => input.graphType,
            options: EctoplannerGraphTypeOptions,
            label: T.ectoplanner.graphs.graphtype
          },
          {
            modelType: ModelType.OPTIONS,
            key: (input) => input.scatterPlotXAxisSeriesId,
            visible: (input) => input.graphType === BuildGraphType.Scatter,
            options: (
              _unused,
              input: BuildGraphResponse,
              environment: EctoplannerEditGraphEnvironment
            ) => {
              return _.map(input?.series, (series) => ({
                label: (
                  <>
                    {environment.seriesById[series.id]?.name}{' '}
                    {translateEctoplannerUnit(
                      environment.seriesById?.[series.id]?.unit
                    )}
                  </>
                ),
                value: series.id
              }));
            },
            isClearable: true,
            label: T.ectoplanner.graphs.scatterxaxisseriesid
          },
          {
            visible: (input) => input.graphType !== BuildGraphType.Scatter,
            modelType: ModelType.SPACE,
            key: (input) => input
          }
        ]
      }
    ]
  }
];

const EctoplannerEditGraphDialog = ({
  isOpen,
  onModalClose,
  timeseries,
  currentCollection,
  setCurrentCollection,
  seriesById
}: EctoplannerEditGraphDialogProps) => {
  const [editSignalIndex, setEditSignalIndex] = useState<number>(-1);
  const [editSettings, setEditSettings] = useState<BuildGraphSeries>(null);

  const [nodes, selectedNodes, setSelectedId, currentCategory] =
    useTimeseriesTreeViewNodes({
      timeseries
    });

  const onDeleteAllSignalsClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setCurrentCollection((oldCollection) => ({
        ...oldCollection,
        series: []
      }));
    }, [setCurrentCollection]);

  const onAddTimeSeries = useCallback(
    (timeSeries: EctoplannerTimeSeries) => {
      setCurrentCollection((oldCollection) => ({
        ...oldCollection,
        series: [
          ...oldCollection.series,
          {
            id: timeSeries.id,
            aggregation:
              timeSeries.unit === 'kWh'
                ? BuildGraphAggregation.Sum
                : BuildGraphAggregation.Average
          }
        ]
      }));
    },
    [setCurrentCollection]
  );

  const onEditSeriesSettings = useCallback(
    (settings: BuildGraphSeries, index: number) => {
      setCurrentCollection((oldCollection) => {
        const newSeries = [...oldCollection.series];
        newSeries[index] = {
          ...newSeries[index],
          ...settings
        };

        return {
          ...oldCollection,
          series: newSeries
        };
      });
    },
    [setCurrentCollection]
  );

  const selectedSignalRows: SelectedSignal[] = useMemo(() => {
    return _.filter(
      currentCollection.series.map((seriesReference) => ({
        series: seriesById[seriesReference.id],
        reference: seriesReference
      })),
      (x) => x.series != null
    );
  }, [currentCollection.series, seriesById]);

  const selectedColumns: DataTableColumnProps<SelectedSignal>[] =
    useMemo(() => {
      return [
        {
          dataKey: 'series',
          dataFormatter: (signal: EctoplannerTimeSeries, object) => {
            let subtitle = signal?.category;

            if (signal?.subcategory) {
              subtitle += ' - ' + signal.subcategory;
            }

            if (signal?.asset_name) {
              subtitle += ' - ' + signal.asset_name;
            }

            if (signal?.building_name) {
              subtitle += ' - ' + signal.building_name;
            }

            if (signal?.is_coordinated !== undefined) {
              subtitle +=
                ' - ' +
                (signal?.is_coordinated
                  ? T.ectoplanner.secosim.columns.coordinated
                  : T.ectoplanner.secosim.columns.noncoordinated);
            }

            return (
              <TableColumn
                icon={<Icons.Signal />}
                title={
                  <>
                    {signal.name} {translateEctoplannerUnit(signal.unit)} -{' '}
                    {signal.type}
                  </>
                }
                subtitle={
                  <>
                    {subtitle}
                    <br />
                    {T.format(
                      T.graphs.aggregationformat,
                      T.ectoplanner.graphs.aggregations[
                        object.reference.aggregation.toLowerCase() as keyof typeof T.ectoplanner.graphs.aggregations
                      ]
                    )}
                  </>
                }
              />
            );
          }
        },
        ...standardColumns<SelectedSignal>({
          onEdit(_item, index) {
            setEditSignalIndex(index);
            setEditSettings({
              ..._item.reference
            });
          },
          onDelete(item) {
            setCurrentCollection((oldCollection) => ({
              ...oldCollection,
              series: _.filter(
                oldCollection.series,
                (x) => x.id !== item.reference.id
              )
            }));
          }
        })
      ];
    }, [setCurrentCollection]);

  const environment: EctoplannerEditGraphEnvironment = useMemo(() => {
    return {
      seriesById
    };
  }, [seriesById]);

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        headerIcon={Icons.Edit}
        onModalClose={onModalClose}
        title={T.ectoplanner.graphs.editgraph}
        onConfirmClick={onModalClose}
        className={styles.dialog}
        messageBodyClassName={styles.body}
      >
        <div className={styles.container}>
          <div className={styles.form}>
            <ModelForm
              sections={sections}
              input={currentCollection}
              setInput={setCurrentCollection}
              environment={environment}
            />
          </div>
          <div className={styles.pickerContainer}>
            <div className={styles.item}>
              <TreeView
                embedded
                nodes={nodes}
                onClickNode={(node) => {
                  setSelectedId(node.id);
                }}
                selectedNodes={selectedNodes}
                selectFolder={false}
              />
            </div>
            <div className={styles.item}>
              {currentCategory && (
                <TimeseriesTable
                  onAddTimeSeries={onAddTimeSeries}
                  timeseries={currentCategory.timeseries}
                  parentName={currentCategory.name}
                  selectedSeries={currentCollection.series}
                />
              )}
            </div>
            <div className={styles.item}>
              <Flex className={styles.paddedArea}>
                <FlexItem grow={1}>
                  <span className={styles.selectedHeader}>
                    {T.ectoplanner.graphs.selectedsignals}
                  </span>
                </FlexItem>
                <FlexItem>
                  <Button onClick={onDeleteAllSignalsClick} compact>
                    {T.ectoplanner.graphs.clear}
                  </Button>
                </FlexItem>
              </Flex>
              <DataTable<SelectedSignal>
                disableHeader
                data={selectedSignalRows}
                inline
                columns={selectedColumns}
                showNoticeHeaders={false}
                noDataText={
                  <div className={styles.paddedArea}>
                    {T.ectoplanner.graphs.nosignals}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </ActionModal>
      <ActionModal
        isOpen={editSignalIndex !== -1}
        onModalClose={() => {
          setEditSettings(null);
          setEditSignalIndex(-1);
        }}
        headerIcon={Icons.Edit}
        title={T.ectoplanner.graphs.editsignalsettings}
        onConfirmClick={() => {
          setEditSettings(null);
          onEditSeriesSettings(editSettings, editSignalIndex);
          setEditSignalIndex(-1);
        }}
      >
        <KeyValueSelectableInput
          keyText={T.ectoplanner.graphs.aggregation}
          options={EctoplannerAggregationOptions}
          value={EctoplannerAggregationOptions.find(
            (x) => x.value === editSettings?.aggregation
          )}
          onChange={(newValue) => {
            setEditSettings((oldEditSettings) => ({
              ...oldEditSettings,
              aggregation: newValue.value
            }));
          }}
        />
      </ActionModal>
    </>
  );
};

export default React.memo(EctoplannerEditGraphDialog);
