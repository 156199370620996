import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import EditEctoplannerTechnologies from 'js/components/Ectoplanner/EditEctoplannerTechnologies';
import EditEctoplannerBuildings from 'js/components/Ectoplanner/EditEctoplannerBuildings';
import styles from './EditEctoplannerProject.module.css';

import {
  AbsorptionChillerBuTech,
  AccuTankBuTech,
  AquiferStorageBuTech,
  CHPBUTech,
  ColdStorageBuTech,
  CompressionChillerBuTech,
  DistrictCoolingBuTech,
  DistrictHeatingBuTech,
  EcologicalImpactSections,
  EconomicParametersSections,
  ElectricalHeaterBuTech,
  EnergyCostsSections,
  GasBoilerBuTech,
  getBuildingNproSections,
  GSHeatPumpBuTech,
  HeatStorageBuTech,
  InvestmentSections,
  LocationSections,
  PhotovoltaicTech,
  ReferenceSystemSections,
  RevHeatPumpBuTech1,
  RevHeatPumpBuTech2,
  SensitivitySections,
  SimpleHeatPumpBuTech,
  SolarThermalTech,
  WasteCoolingTech,
  WasteHeat1Tech,
  WasteHeat2Tech
} from 'js/components/Ectoplanner/EctoplannerModels';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

import {
  EctoplannerBuildStatus,
  EctoplannerBuildStatusProgress,
  EctoplannerFormEnvironment,
  EctoplannerFormError
} from 'js/components/Ectoplanner/EctoplannerTypes';
import { WeatherCountryResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import { OptionWithIcon } from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import {
  ModelBoolFunctionProperty,
  ModelFormSectionType,
  ModelStringFunctionProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import _ from 'lodash';
import {
  flattenModelFormSections,
  getPathFromModelKeyFunc,
  getPathStringFromModelKeyFunc
} from 'ecto-common/lib/ModelForm/formUtils';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useMutation } from '@tanstack/react-query';
import { compileFormPromise } from 'js/components/Ectoplanner/EctoplannerModelUtils';
import { modelFormSectionsToModelsWithParent } from 'ecto-common/lib/ModelForm/formUtils';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import Notice from 'ecto-common/lib/Notice/Notice';
import Heading from 'ecto-common/lib/Heading/Heading';
import { EctoplannerProgressBar } from 'js/components/Ectoplanner/EctoplannerResultView';
import { BatteryBuTech } from './EctoplannerModels';
import EditEctoplannerBusinessModel from 'js/components/Ectoplanner/EditEctoplannerBusinessModel';

export const balancingUnitHeatTechnologies = _.orderBy(
  [
    SimpleHeatPumpBuTech,
    ElectricalHeaterBuTech,
    CHPBUTech,
    GasBoilerBuTech,
    SolarThermalTech,
    DistrictHeatingBuTech,
    HeatStorageBuTech,
    WasteHeat1Tech,
    WasteHeat2Tech
  ],
  'label'
);

export const balancingUnitCoolingTechnologies = _.orderBy(
  [
    AbsorptionChillerBuTech,
    CompressionChillerBuTech,
    DistrictCoolingBuTech,
    ColdStorageBuTech,
    WasteCoolingTech
  ],
  'label'
);

export const balancingUnitCombinedTechnologies = _.orderBy(
  [
    AccuTankBuTech,
    RevHeatPumpBuTech1,
    RevHeatPumpBuTech2,
    AquiferStorageBuTech,
    GSHeatPumpBuTech
  ],
  'label'
);
export const balancingUnitElectricityTechnologies = _.orderBy(
  [PhotovoltaicTech, BatteryBuTech],
  'label'
);

export const balancingUnitTechnologies = [
  ...balancingUnitHeatTechnologies,
  ...balancingUnitCoolingTechnologies,
  ...balancingUnitCombinedTechnologies,
  ...balancingUnitElectricityTechnologies
];

export const balancingUnitSections = _.orderBy(
  balancingUnitTechnologies.map((tech) => tech.section),
  'label'
);

export const ectoplannerBusinessModelSections = [
  ...EnergyCostsSections,
  ...EconomicParametersSections,
  ...EcologicalImpactSections,
  ...ReferenceSystemSections,
  ...SensitivitySections,
  ...InvestmentSections
];

export const balancingUnitTechKeypaths = _.compact(
  balancingUnitSections.map((section) =>
    getPathStringFromModelKeyFunc(
      section.models.find((model) =>
        getPathStringFromModelKeyFunc(model.key).endsWith('.enabled')
      )?.key
    )
  )
);

export type OptionWithIconAndView = OptionWithIcon & {
  view: React.ReactNode;
};

const EmptyErrors: EctoplannerFormError[] = [];
const EmptyErrorSections: Record<string, EctoplannerFormError[]> = {};

export function useEctoplannerFormValidation({
  form,
  cityData,
  projectId
}: {
  form: EctoplannerForm;
  cityData: object;
  projectId: string;
}) {
  const [allSectionsFlat, allModels] = useMemo(() => {
    const allSections: ModelFormSectionType<EctoplannerForm>[] = _.concat(
      LocationSections,
      ectoplannerBusinessModelSections,
      balancingUnitSections,
      getBuildingNproSections(form?.buildings ?? [], cityData)
    );

    return [
      flattenModelFormSections(allSections),
      modelFormSectionsToModelsWithParent(allSections)
    ];
  }, [cityData, form?.buildings]);

  const [formErrors, formErrorSections] = useMemo(() => {
    const environment = { projectId: projectId };
    const ret: EctoplannerFormError[] =
      form == null
        ? []
        : _.filter(
            _.map(allModels, (model) => {
              const value = _.get(form, getPathFromModelKeyFunc(model.key));

              let errorText = (
                model.errorText as ModelStringFunctionProperty<
                  EctoplannerForm,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  any,
                  EctoplannerFormEnvironment
                >
              )(value, form, environment, model);

              if (errorText == null && model.hasError != null) {
                let hasError = false;
                if (_.isFunction(model.hasError)) {
                  hasError = (
                    model.hasError as ModelBoolFunctionProperty<
                      EctoplannerForm,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      any,
                      EctoplannerFormEnvironment
                    >
                  )(value, form, environment, model);
                } else if (_.isBoolean(model.hasError)) {
                  hasError = model.hasError;
                }

                if (hasError) {
                  errorText = T.ectoplanner.form.shared.error;
                }
              }

              const sectionWithSubsection = (
                subSection: ModelFormSectionType<EctoplannerForm>
              ) =>
                allSectionsFlat.find((section) =>
                  section.sections?.find(
                    (childSection) => childSection === subSection
                  )
                );

              let prefix = '';

              if (errorText != null) {
                let parentSection = sectionWithSubsection(model.section);

                while (parentSection != null) {
                  prefix = parentSection.label + ' > ' + prefix;
                  parentSection = sectionWithSubsection(parentSection);
                }
              }

              return {
                sectionTitle: prefix + model.section.label,
                errorLabel: model.label,
                errorKeyPath: getPathStringFromModelKeyFunc(model.key),
                errorText
              };
            }),
            (error) => error.errorText != null
          );

    // To avoid re-rendering
    if (ret.length === 0) {
      return [EmptyErrors, EmptyErrorSections];
    }

    return [ret, _.groupBy(ret, 'sectionTitle')];
  }, [allModels, allSectionsFlat, form, projectId]);

  const hasErrors = !_.isEmpty(formErrorSections);

  return [hasErrors, formErrors, formErrorSections] as const;
}

export function useEctoplannerValidation({
  form,
  currentWeatherStationId,
  hasErrors
}: {
  form: EctoplannerForm;
  hasErrors: boolean;
  currentWeatherStationId: string;
}) {
  const hasNoTechs =
    form != null &&
    !_.some(
      balancingUnitTechKeypaths,
      (keyPath) => _.get(form, keyPath) === true
    );
  const hasEmptyProject =
    currentWeatherStationId == null || form?.buildings?.length === 0;
  const component = (
    <>
      {' '}
      {hasNoTechs && (
        <ErrorNotice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.form.notechs.label}{' '}
        </ErrorNotice>
      )}
      {hasErrors && !hasEmptyProject && (
        <ErrorNotice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.invalidform.label}{' '}
        </ErrorNotice>
      )}
      {hasEmptyProject && (
        <Notice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.form.emptyform.label}{' '}
        </Notice>
      )}
    </>
  );

  return [component, hasNoTechs] as const;
}

export function useEctoplannerFormCalculation({
  form,
  setForm,
  hasErrors,
  cityData,
  airTemp,
  buildId
}: {
  form: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  hasErrors: boolean;
  cityData?: object;
  airTemp?: string;
  buildId?: string;
}) {
  // This is a bit messy: When we do a calculation, the result of that will change the form state (the form
  // includes the results from the calculation). However, a calculation will also be triggered by other form
  // changes (that result from user input). We do not want to create a loop where a change in the form causes a
  // calculation, which causes a change in the form, and so on... So we explicitly set calculationTrigger for
  // the explicit cases where form changes should result in a recalculation. We also use a ref to keep track
  // of the last known calculation trigger, so that we can check the value during render, but only the first time it changes.
  const [calculationTrigger, setCalculationTrigger] = useReducer(
    (x) => x + 1,
    0
  );
  const lastCalculationTrigger = useRef(-1);
  const calculationIndex = useRef(0);
  const lastBuildIdRef = useRef<string>(buildId);
  const [checksums, setChecksums] = useState<Record<string, string>>({});

  const stableDebounce = useMemo(() => {
    return _.debounce((func: () => void) => {
      func();
    }, 300);
  }, []);

  const calculateProfilesMutation = useMutation({
    mutationFn: compileFormPromise,
    onSuccess: (data) => {
      // Need to make sure that a more recent calculation hasn't been triggered. If so, we should not update data.
      if (calculationIndex.current === data.calculationIndex) {
        setForm(data.form);
        setChecksums((oldChecksums) => ({
          ...oldChecksums,
          [buildId]: data.checksum
        }));
      }
    }
  });

  // If form has changed, and we have set the trigger calculation flag, we should trigger a calculation.
  // But do it through the debounce so that we don't spam calculations when the user updates the form
  // quickly.
  // However, if we do it for the first time, we should not debounce it. Wait for an explicit
  // trigger (calculationTrigger > 0) which will happen once the form and weather + cityData
  // has been fetched (so migrations / building type verification etc can happen)
  if (
    (calculationTrigger !== lastCalculationTrigger.current ||
      lastBuildIdRef.current !== buildId) &&
    airTemp != null &&
    cityData != null &&
    form != null &&
    calculationTrigger > 0
  ) {
    lastBuildIdRef.current = buildId;
    lastCalculationTrigger.current = calculationTrigger;

    if (calculationTrigger === 1 && !hasErrors) {
      calculateProfilesMutation.mutate({
        inputForm: { ...form },
        calculationIndex: calculationIndex.current,
        cityData,
        airTemp
      });
    } else {
      stableDebounce(() => {
        if (!hasErrors) {
          calculateProfilesMutation.mutate({
            inputForm: { ...form },
            calculationIndex: calculationIndex.current,
            cityData,
            airTemp
          });
        }
      });
    }
  }

  const triggerCalculation = useCallback(() => {
    calculationIndex.current++;
    setCalculationTrigger();
  }, []);

  return [triggerCalculation, checksums[buildId]] as const;
}

export function useEctoplannerFormOptions({
  projectId,
  form,
  setFormFromUserInput,
  formErrors,
  weatherCountries,
  cityData
}: {
  projectId: string;
  form: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  formErrors: EctoplannerFormError[];
  weatherCountries: WeatherCountryResponse[];
  cityData: object;
}): OptionWithIconAndView[] {
  return useMemo(() => {
    const formOptions = [
      {
        icon: <Icons.Building />,
        label: T.ectoplanner.form.shared.buildings,
        value: 'buildings',
        view: form && (
          <EditEctoplannerBuildings
            formErrors={formErrors}
            form={form}
            setFormFromUserInput={setFormFromUserInput}
            projectId={projectId}
            weatherCountries={weatherCountries}
            cityData={cityData}
          />
        )
      },
      {
        icon: <Icons.TemperatureImpact />,
        label: T.ectoplanner.sections.balancingunit,
        value: 'balancingunit',
        view: form && (
          <EditEctoplannerTechnologies
            form={form}
            setForm={setFormFromUserInput}
            projectId={projectId}
          />
        )
      },
      {
        icon: <Icons.File />,
        label: T.ectoplanner.sections.modelparams,
        value: 'modelparams',
        view: form && (
          <EditEctoplannerBusinessModel
            form={form}
            setForm={setFormFromUserInput}
            projectId={projectId}
          />
        )
      }
    ];
    return formOptions;
  }, [
    cityData,
    form,
    formErrors,
    projectId,
    setFormFromUserInput,
    weatherCountries
  ]);
}

export const getEctoplannerInfoView = (
  isRunningCalculation: boolean,
  noSolutionFound: boolean,
  noResults: boolean,
  status: EctoplannerBuildStatus,
  containerClassName: string,
  noSolutionFoundMessage: string = null,
  configurationErrorMessage: string = null
) => {
  const notCalculated =
    status == null || status === EctoplannerBuildStatus.Created;

  if (noSolutionFound && !isRunningCalculation) {
    const message =
      noSolutionFoundMessage ??
      configurationErrorMessage ??
      T.ectoplanner.calculations.nosolutionfound;

    return (
      <div className={containerClassName}>
        <Heading level={3}>
          {T.ectoplanner.calculations.calculationfailed}
        </Heading>
        {message}
      </div>
    );
  } else if (isRunningCalculation) {
    return (
      <div className={containerClassName}>
        {T.ectoplanner.calculations.calculating}

        <EctoplannerProgressBar
          isLoading
          progress={EctoplannerBuildStatusProgress[status] ?? 0}
        />
      </div>
    );
  } else if (notCalculated) {
    return (
      <div className={containerClassName}>
        <Heading level={3}>{T.ectoplanner.calculations.notcalculated}</Heading>
        {T.ectoplanner.calculations.notcalculatedinfo}
      </div>
    );
  } else if (noResults || status === EctoplannerBuildStatus.Error) {
    return (
      <div className={containerClassName}>
        <Heading level={3}>
          {T.ectoplanner.calculations.calculationfailed}
        </Heading>
        {T.common.unknownerror}
      </div>
    );
  }

  return null;
};
