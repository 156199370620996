import React from 'react';
import {
  GetAlarmUrlFunction,
  GetSignalsUrlFunction
} from 'ecto-common/lib/hooks/UrlContext';
import { NodePathItem } from 'ecto-common/lib/API/AlarmsAPIGen';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';

const AlarmDetailsColumn = ({
  tenantId,
  nodePath,
  getAlarmUrl,
  getSignalsUrl
}: {
  tenantId: string;
  nodePath: NodePathItem[];
  getAlarmUrl: GetAlarmUrlFunction;
  getSignalsUrl: GetSignalsUrlFunction;
}) => {
  const rootPath = nodePath[0];
  const secondaryPath = nodePath[1];

  const subtitle = nodePath
    .slice(1)
    .map((pathItem) => pathItem.name)
    .join(' > ');

  return (
    <TableColumn
      title={rootPath.name}
      titleLink={
        secondaryPath
          ? getSignalsUrl(tenantId, secondaryPath.id, rootPath.id)
          : null
      }
      subtitle={subtitle}
      subtitleLink={
        secondaryPath ? getAlarmUrl(tenantId, secondaryPath.id, null) : null
      }
    />
  );
};
export default AlarmDetailsColumn;
