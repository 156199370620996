import React from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { AlarmEventModel, AlarmModel } from 'ecto-common/lib/API/AlarmsAPIGen';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import styles from '../AlarmView.module.css';
import classNames from 'classnames';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

const AcknowledgedAtText = ({
  alarm,
  isAcknowledged
}: {
  alarm: AlarmEventModel | AlarmModel;
  isAcknowledged: boolean;
}) => {
  const text = isAcknowledged
    ? alarm.acknowledgedBy
    : T.alarms.unacknowledgedtext;
  const date =
    alarm.acknowledgedAt && moment.tz(alarm.acknowledgedAt, DEFAULT_TIMEZONE);

  return (
    <TableColumn
      icon={
        isAcknowledged && (
          <div className={styles.acknowledgedIcon}>
            <Icons.CheckmarkCircle />
          </div>
        )
      }
      title={
        <div
          className={classNames(
            styles.acknowledgedText,
            !isAcknowledged && styles.unacknowledgedText
          )}
        >
          {text}
        </div>
      }
      subtitle={
        date && date.format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME))
      }
    />
  );
};
export default AcknowledgedAtText;
