import React from 'react';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { ectoplannerHorizontalWeights } from 'js/components/Ectoplanner/EctoplannerModels';
import { EctoplannerProfileEditor } from 'js/components/Ectoplanner/EctoplannerProfileEditor';
import { EctoplannerProfileTypes } from 'js/components/Ectoplanner/EctoplannerTypes';
import _ from 'lodash';
import {
  SecosimAsset,
  SecosimAssetBattery,
  SecosimAssetBoiler,
  SecosimAssetBufferTank,
  SecosimAssetCoolingDirect,
  SecosimAssetDieselGenerator,
  SecosimAssetEV,
  SecosimAssetGasGenerator,
  SecosimAssetHeatPump,
  SecosimAssetPV,
  SecosimAssetThermalFlex,
  SecosimForm
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

function patchModels<T extends object>(
  models: ModelDefinition<T>[]
): ModelDefinition<T>[] {
  return models.map((model) => {
    return {
      ...model,
      isHorizontal: true,
      horizontalWeights: model.horizontalWeights || ectoplannerHorizontalWeights
    };
  });
}

export const ScenarioDefinitionModels: ModelDefinition<SecosimForm>[] =
  patchModels([
    {
      key: (input) => input.parameters.goal,
      label: T.ectoplanner.secosim.models.goal.title,
      modelType: ModelType.OPTIONS,
      helpText: T.ectoplanner.secosim.models.goal.helptext,
      options: [
        {
          label: T.ectoplanner.secosim.models.goal.cost,
          value: 'cost'
        },
        {
          label: T.ectoplanner.secosim.models.goal.co2,
          value: 'co2'
        },
        {
          label: T.ectoplanner.secosim.models.goal.reference,
          value: 'reference'
        }
      ],
      withDivider: true
    },
    {
      key: (input) => input.parameters.ATES,
      label: T.ectoplanner.secosim.models.ates.title,
      helpText: T.ectoplanner.secosim.models.ates.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.parameters.diesel_price,
      label: T.ectoplanner.secosim.models.diesel_price.title,
      helpText: T.ectoplanner.secosim.models.diesel_price.helptext,
      unit: T.ectoplanner.units.eurliters,
      modelType: ModelType.NUMBER
    }
  ]);

const NameModel: ModelDefinition<SecosimAsset> = {
  key: (input) => input.name,
  modelType: ModelType.TEXT,
  label: T.common.name,
  helpText: T.ectoplanner.secosim.models.name.helptext,
  hasError: isNullOrWhitespace
};

export const SecosimPVModels: ModelDefinition<SecosimAssetPV>[] =
  patchModels<SecosimAssetPV>([
    NameModel,
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.pv_capacity.title,
      helpText: T.ectoplanner.secosim.models.pv_capacity.helptext,
      unit: T.ectoplanner.units.kwp,
      modelType: ModelType.NUMBER,
      hasError: (input) => input == null
    },
    {
      key: (input) => input.generation_profile,
      label: T.ectoplanner.secosim.models.pv_generation_profile.title,
      helpText: T.ectoplanner.secosim.models.pv_generation_profile.helptext,
      modelType: ModelType.CUSTOM,
      render: (props, model) => (
        <EctoplannerProfileEditor<SecosimAssetPV, object>
          {...props}
          model={model}
          profileType={EctoplannerProfileTypes.Energy}
          allowDelete={false}
        />
      ),
      hasError: (input) => input == null
    }
  ]);

export const SecosimBatteryModels: ModelDefinition<SecosimAssetBattery>[] =
  patchModels<SecosimAssetBattery>([
    NameModel,
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.battery_capacity.title,
      helpText: T.ectoplanner.secosim.models.battery_capacity.helptext,
      unit: T.ectoplanner.units.kwh,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.power,
      label: T.ectoplanner.secosim.models.battery_power.title,
      helpText: T.ectoplanner.secosim.models.battery_power.helptext,
      unit: T.ectoplanner.units.kwel,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.efficiency,
      label: T.ectoplanner.secosim.models.battery_efficiency.title,
      helpText: T.ectoplanner.secosim.models.battery_efficiency.helptext,
      unit: T.ectoplanner.units.percent,
      modelType: ModelType.NUMBER
    }
  ]);

export const GridConnectionSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.grid_connection,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].name,
        label: T.common.name,
        modelType: ModelType.TEXT,
        helpText: T.ectoplanner.secosim.models.name.helptext
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].parameters.network.grid_capacity,
        label: T.ectoplanner.secosim.models.grid_capacity.title,
        helpText: T.ectoplanner.secosim.models.grid_capacity_building.helptext,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwel
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].parameters.network.feed_in_capacity,
        label: T.ectoplanner.secosim.models.feed_in_capacity.title,
        helpText:
          T.ectoplanner.secosim.models.feed_in_capacity_building.helptext,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwel
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].parameters.network
            .heat_to_ates_capacity,
        label: T.ectoplanner.secosim.models.heat_to_ates_capacity.title,
        helpText: T.ectoplanner.secosim.models.heat_to_ates_capacity.helptext,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      }
    ])
  }
];

const heatPumpSourceOptions = [
  {
    value: 'ates',
    label: T.ectoplanner.secosim.models.hp_sinksource.ates
  },
  {
    value: 'air',
    label: T.ectoplanner.secosim.models.hp_sinksource.air
  },
  {
    value: 'for_cooling',
    label: T.ectoplanner.secosim.models.hp_sinksource.for_cooling
  }
];

const heatPumpSinkOptions = [
  {
    value: 'ates',
    label: T.ectoplanner.secosim.models.hp_sinksource.ates
  },
  {
    value: 'air',
    label: T.ectoplanner.secosim.models.hp_sinksource.air
  },
  {
    value: 'for_heating',
    label: T.ectoplanner.secosim.models.hp_sinksource.for_heating
  },
  {
    value: 'for_dhw',
    label: T.ectoplanner.secosim.models.hp_sinksource.for_dhw
  }
];

export const getSecosimHeatPumpName = (source: string, sink: string) => {
  const sinkName =
    heatPumpSinkOptions.find((option) => option.value === sink)?.label ?? '';
  const sourceName =
    heatPumpSourceOptions.find((option) => option.value === source)?.label ??
    '';

  return T.ectoplanner.form.shared.heatpump + ' ' + sourceName + ' ' + sinkName;
};

export const SecosimHeatPumpModels: ModelDefinition<SecosimAssetHeatPump>[] =
  patchModels([
    {
      key: (input) => input.name,
      label: T.common.name,
      modelType: ModelType.TEXT,
      helpText: T.ectoplanner.secosim.models.name.helptext
    },
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.hp_capacity.title,
      helpText: T.ectoplanner.secosim.models.hp_capacity.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwth
    },
    {
      key: (input) => input.eta,
      label: T.ectoplanner.secosim.models.hp_eta.title,
      helpText: T.ectoplanner.secosim.models.hp_eta.helptext,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.source,
      label: T.ectoplanner.secosim.models.hp_source.title,
      helpText: T.ectoplanner.secosim.models.hp_source.helptext,
      modelType: ModelType.OPTIONS,
      options: heatPumpSourceOptions,
      onDidUpdate: (
        _key: string[],
        value: string,
        form: SecosimAssetHeatPump
      ) => {
        const ret: [(input: SecosimAssetHeatPump) => unknown, unknown][] = [
          [(input) => input.name, getSecosimHeatPumpName(value, form.sink)]
        ];

        return ret;
      }
    },
    {
      key: (input) => input.sink,
      label: T.ectoplanner.secosim.models.hp_sink.title,
      helpText: T.ectoplanner.secosim.models.hp_sink.helptext,
      modelType: ModelType.OPTIONS,
      onDidUpdate: (
        _key: string[],
        value: string,
        form: SecosimAssetHeatPump
      ) => {
        const ret: [(input: SecosimAssetHeatPump) => unknown, unknown][] = [
          [(input) => input.name, getSecosimHeatPumpName(form.source, value)]
        ];

        return ret;
      },
      options: heatPumpSinkOptions
    }
  ]);

export const SecosimCoolingDirectModels: ModelDefinition<SecosimAssetCoolingDirect>[] =
  patchModels<SecosimAssetCoolingDirect>([
    NameModel,
    {
      key: (input) => input.cop,
      label: T.ectoplanner.secosim.models.hp_pump_cop.title,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.cooling_capacity.title,
      helpText: T.ectoplanner.secosim.models.cooling_capacity.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwth
    }
  ]);

export const BufferTankModels: ModelDefinition<SecosimAssetBufferTank>[] =
  patchModels<SecosimAssetBufferTank>([
    NameModel,
    {
      key: (input) => input.for_heating,
      label: T.ectoplanner.secosim.models.shared.for_heating.title,
      helpText: T.ectoplanner.secosim.models.shared.for_heating.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.for_cooling,
      label: T.ectoplanner.secosim.models.shared.for_cooling.title,
      helpText: T.ectoplanner.secosim.models.shared.for_cooling.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.for_dhw,
      label: T.ectoplanner.secosim.models.shared.for_dhw.title,
      helpText: T.ectoplanner.secosim.models.shared.for_dhw.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.indoors,
      label: T.ectoplanner.secosim.models.buffertank.indoors.title,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.volume,
      label: T.ectoplanner.secosim.models.volume_buffer.title,
      helpText: T.ectoplanner.secosim.models.volume_buffer.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.m3
    },
    {
      key: (input) => input.max_temp,
      label: T.ectoplanner.secosim.models.max_temp_buffer.title,
      helpText: T.ectoplanner.secosim.models.max_temp_buffer.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.degc
    },
    {
      key: (input) => input.min_temp,
      label: T.ectoplanner.secosim.models.min_temp_buffer.title,
      helpText: T.ectoplanner.secosim.models.min_temp_buffer.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.degc
    },
    {
      key: (input) => input.heat_loss,
      label: T.ectoplanner.secosim.models.loss_buffer.title,
      helpText: T.ectoplanner.secosim.models.loss_buffer.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwh
    }
  ]);

// thermal_capacity is kWh/K

export const SecosimThermalFlexModels: ModelDefinition<SecosimAssetThermalFlex>[] =
  patchModels<SecosimAssetThermalFlex>([
    NameModel,
    {
      key: (input) => input.name,
      label: T.common.name,
      modelType: ModelType.TEXT,
      helpText: T.ectoplanner.secosim.models.name.helptext
    },
    {
      key: (input) => input.volume_indoors,
      label: T.ectoplanner.secosim.models.thermal_flex.volume_indoors.title,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.volume_indoors.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.m3
    },
    {
      key: (input) => input.buffering_power,
      label: T.ectoplanner.secosim.models.thermal_flex.buffering_power.title,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.buffering_power.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwth
    },
    {
      key: (input) => input.delta_down,
      label: T.ectoplanner.secosim.models.thermal_flex.delta_down.title,
      helpText: T.ectoplanner.secosim.models.thermal_flex.delta_down.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.k
    },
    {
      key: (input) => input.delta_up,
      label: T.ectoplanner.secosim.models.thermal_flex.delta_up.title,
      helpText: T.ectoplanner.secosim.models.thermal_flex.delta_up.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.k
    },
    {
      key: (input) => input.for_heating,
      label: T.ectoplanner.secosim.models.thermal_flex.for_heating.title,
      helpText: T.ectoplanner.secosim.models.shared.for_heating.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.storage_specification.thermal_capacity,
      label: T.ectoplanner.secosim.models.thermal_flex.thermal_capacity.title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.storage_spec.thermal_capacity
          .helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwhk
    },
    {
      key: (input) => input.storage_specification.thermal_conductance_indoor,
      label:
        T.ectoplanner.secosim.models.thermal_flex.thermal_conductance_indoor
          .title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.storage_spec
          .thermal_conductance_indoor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwk
    },
    {
      key: (input) => input.wall_specification.thermal_capacity,
      label: T.ectoplanner.secosim.models.thermal_flex.thermal_capacity.title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.wall_spec.thermal_capacity
          .helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwhk
    },
    {
      key: (input) => input.wall_specification.thermal_conductance_indoor,
      label:
        T.ectoplanner.secosim.models.thermal_flex.thermal_conductance_indoor
          .title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.wall_spec
          .thermal_conductance_indoor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwk
    },
    {
      key: (input) => input.wall_specification.thermal_conductance_outdoor,
      label:
        T.ectoplanner.secosim.models.thermal_flex.thermal_conductance_outdoor
          .title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.wall_spec
          .thermal_conductance_outdoor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwk
    },
    {
      key: (input) => input.ceiling_specification.thermal_capacity,
      label: T.ectoplanner.secosim.models.thermal_flex.thermal_capacity.title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.ceiling_spec.thermal_capacity
          .helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwhk
    },
    {
      key: (input) => input.ceiling_specification.thermal_conductance_indoor,
      label:
        T.ectoplanner.secosim.models.thermal_flex.thermal_conductance_indoor
          .title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.ceiling_spec
          .thermal_conductance_indoor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwk
    },
    {
      key: (input) => input.ceiling_specification.thermal_conductance_outdoor,
      label:
        T.ectoplanner.secosim.models.thermal_flex.thermal_conductance_outdoor
          .title,
      useTooltipHelpTexts: false,
      helpText:
        T.ectoplanner.secosim.models.thermal_flex.ceiling_spec
          .thermal_conductance_outdoor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwk
    }
  ]);

export const SecosimEVModels: ModelDefinition<SecosimAssetEV>[] =
  patchModels<SecosimAssetEV>([
    NameModel,
    {
      key: (input) => input.efficiency,
      label: T.ectoplanner.secosim.models.shared.efficiency,
      helpText: T.ectoplanner.secosim.models.ev.efficiency.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwhelkwhel
    },
    {
      key: (input) => input.fast_charging,
      label: T.ectoplanner.secosim.models.ev.fast_charging.title,
      helpText: T.ectoplanner.secosim.models.ev.fast_charging.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.name,
      label: T.common.name,
      helpText: T.ectoplanner.secosim.models.name.helptext,
      modelType: ModelType.TEXT
    },
    {
      key: (input) => input.profile_type,
      label: T.ectoplanner.secosim.models.ev.profile_type.title,
      helpText: T.ectoplanner.secosim.models.ev.profile_type.helptext,
      options: [
        {
          label: T.ectoplanner.secosim.models.ev.office.title,
          value: 'office'
        },
        {
          label: T.ectoplanner.secosim.models.ev.household.title,
          value: 'household'
        }
      ],
      modelType: ModelType.OPTIONS
    },
    {
      key: (input) => input.revenue_opt,
      label: T.ectoplanner.secosim.models.ev.revenue_opt.title,
      helpText: T.ectoplanner.secosim.models.ev.revenue_opt.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.seed,
      label: T.ectoplanner.secosim.models.ev.seed.title,
      helpText: T.ectoplanner.secosim.models.ev.seed.helptext,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.usage_revenue,
      label: T.ectoplanner.secosim.models.ev.usage_revenue.title,
      helpText: T.ectoplanner.secosim.models.ev.usage_revenue.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.eurkwh
    },
    {
      key: (input) => input.weekday_num,
      label: T.ectoplanner.secosim.models.ev.weekday_num.title,
      helpText: T.ectoplanner.secosim.models.ev.weekday_num.helptext,
      modelType: ModelType.NUMBER
    },
    {
      key: (input) => input.weekend_num,
      label: T.ectoplanner.secosim.models.ev.weekend_num.title,
      helpText: T.ectoplanner.secosim.models.ev.weekend_num.helptext,
      modelType: ModelType.NUMBER
    }
  ]);

export const SecosimBoilerModels: ModelDefinition<SecosimAssetBoiler>[] =
  patchModels<SecosimAssetBoiler>([
    NameModel,
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.form.shared.capacity,
      helpText: T.ectoplanner.secosim.models.dhw_capacity.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwth
    },
    {
      key: (input) => input.for_heating,
      label: T.ectoplanner.secosim.models.shared.for_heating.title,
      helpText: T.ectoplanner.secosim.models.shared.for_heating.helptext,
      modelType: ModelType.BOOL
    },
    {
      key: (input) => input.for_dhw,
      label: T.ectoplanner.secosim.models.shared.for_dhw.title,
      helpText: T.ectoplanner.secosim.models.shared.for_dhw.helptext,
      modelType: ModelType.BOOL
    }
  ]);

export const SecosimDieselGeneratorModels: ModelDefinition<SecosimAssetDieselGenerator>[] =
  patchModels<SecosimAssetDieselGenerator>([
    NameModel,
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.shared.capacity,
      helpText: T.ectoplanner.secosim.models.generator.capacity.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwel
    },
    {
      key: (input) => input.conversion_factor,
      label: T.ectoplanner.secosim.models.shared.conversion_factor,
      helpText:
        T.ectoplanner.secosim.models.diesel_generator_conversion_factor
          .helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwhell
    }
  ]);

export const SecosimGasGeneratorModels: ModelDefinition<SecosimAssetGasGenerator>[] =
  patchModels<SecosimAssetGasGenerator>([
    NameModel,
    {
      key: (input) => input.capacity,
      label: T.ectoplanner.secosim.models.shared.capacity,
      helpText: T.ectoplanner.secosim.models.generator.capacity.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwel
    },
    {
      key: (input) => input.efficiency,
      label: T.ectoplanner.secosim.models.shared.efficiency,
      helpText:
        T.ectoplanner.secosim.models.gas_generator_conversion_factor.helptext,
      modelType: ModelType.NUMBER,
      unit: T.ectoplanner.units.kwelkwth
    }
  ]);

const SecosimProfileEditor = EctoplannerProfileEditor<SecosimForm, object>;

export const TimeSeriesModels: ModelDefinition<SecosimForm>[] = patchModels([
  {
    key: (input) => input.co2_data,
    label: T.ectoplanner.secosim.models.co2_data.title,
    helpText: T.ectoplanner.secosim.models.co2_data.helptext,
    modelType: ModelType.CUSTOM,
    render: (props, model) => (
      <SecosimProfileEditor
        {...props}
        model={model}
        profileType={EctoplannerProfileTypes.EmissionsGCo2Kwh}
        allowDelete={false}
      />
    )
  },
  {
    key: (input) => input.el_import_price,
    label: T.ectoplanner.secosim.models.el_import_price.title,
    helpText: T.ectoplanner.secosim.models.el_import_price.helptext,
    modelType: ModelType.CUSTOM,
    render: (props, model) => (
      <SecosimProfileEditor
        {...props}
        model={model}
        profileType={EctoplannerProfileTypes.PriceEurMwh}
        allowDelete={false}
      />
    )
  },
  {
    key: (input) => input.el_export_price,
    label: T.ectoplanner.secosim.models.el_export_price.title,
    helpText: T.ectoplanner.secosim.models.el_export_price.helptext,
    modelType: ModelType.CUSTOM,
    render: (props, model) => (
      <SecosimProfileEditor
        {...props}
        model={model}
        profileType={EctoplannerProfileTypes.PriceEurMwh}
        allowDelete={false}
      />
    )
  },
  {
    key: (input) => input.gas_prices,
    label: T.ectoplanner.secosim.models.gas_prices.title,
    helpText: T.ectoplanner.secosim.models.gas_prices.helptext,
    modelType: ModelType.CUSTOM,
    render: (props, model) => (
      <SecosimProfileEditor
        {...props}
        model={model}
        profileType={EctoplannerProfileTypes.PriceEurMwh}
        allowDelete={false}
      />
    )
  },
  {
    key: (input) => input.network.grid_capacity,
    helpText: T.ectoplanner.secosim.models.grid_capacity.helptext,
    label: T.ectoplanner.secosim.models.grid_capacity.title,
    modelType: ModelType.NUMBER,
    unit: T.ectoplanner.units.kwel
  },
  {
    key: (input) => input.network.feed_in_capacity,
    label: T.ectoplanner.secosim.models.feed_in_capacity.title,
    helpText: T.ectoplanner.secosim.models.feed_in_capacity.helptext,
    modelType: ModelType.NUMBER,
    unit: T.ectoplanner.units.kwel
  }
]);

export const BuildingTimeSeriesSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.timeseries,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].el_demand,
        label: T.ectoplanner.secosim.models.el_demand.title,
        helpText: T.ectoplanner.secosim.models.el_demand.helptext,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].demand_heating,
        label: T.ectoplanner.secosim.models.demand_heating.title,
        helpText: T.ectoplanner.secosim.models.demand_heating.helptext,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].demand_dhw,
        label: T.ectoplanner.secosim.models.demand_dhw.title,
        helpText: T.ectoplanner.secosim.models.demand_dhw.helptext,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].demand_cooling,
        label: T.ectoplanner.secosim.models.demand_cooling.title,
        helpText: T.ectoplanner.secosim.models.demand_cooling.helptext,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].demand_temperature,
        label: T.ectoplanner.secosim.models.demand_temperature.title,
        helpText: T.ectoplanner.secosim.models.demand_temperature.helptext,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Temperature}
            allowDelete={false}
          />
        )
      }
    ])
  }
];
