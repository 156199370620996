import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';

import PowerControl from 'js/components/EMPTools/PowerControl/PowerControl';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { SignalGroupLiteResponseModel } from 'ecto-common/lib/API/APIGen';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const collectPowerControls = (
  selectedNode: SingleGridNode
): SignalGroupLiteResponseModel[] => {
  return _.map(selectedNode.powerControls || [], (control) => ({
    ...control,
    parentName: selectedNode.name
  }));
};

interface PowerControlPageProps {
  selectedNode: SingleGridNode;
  onTitleChanged: (title: string[]) => void;
}

const PowerControlPage = ({
  selectedNode,
  onTitleChanged
}: PowerControlPageProps) => {
  const controls = useMemo(
    () => collectPowerControls(selectedNode),
    [selectedNode]
  );
  const { tenantResources } = useContext(TenantContext);

  usePageTitleCallback({
    mainTitle: T.location.tabs.powercontrol,
    subTitle: '',
    onTitleChanged
  });

  if (!hasAccessToResource(ResourceType.POWER_CONTROL, tenantResources)) {
    return (
      <ToolbarContentPage
        title={T.location.tabs.powercontrol}
        showLocationPicker={false}
      >
        {T.powercontrol.unauthorized.text}
      </ToolbarContentPage>
    );
  }

  return (
    <ToolbarContentPage
      title={T.location.tabs.powercontrol}
      padContent={false}
      helpPath={HelpPaths.docs.operator.powercontrol}
    >
      <PowerControl controls={controls} selectedNode={selectedNode} />
    </ToolbarContentPage>
  );
};

export default PowerControlPage;
