import { SecosimWarning } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import React from 'react';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import dimensions from 'ecto-common/lib/styles/dimensions';
import T from 'ecto-common/lib/lang/Language';

type SecosimResultWarningsProps = {
  warnings: SecosimWarning[];
};

const columns: DataTableColumnProps<SecosimWarning>[] = [
  {
    dataKey: 'message',
    label: T.ectoplanner.secosim.results.warnings
  }
];

const SecosimResultWarnings = ({ warnings }: SecosimResultWarningsProps) => {
  if (warnings == null) {
    return null;
  }

  const filteredWarnings = warnings.filter(
    (warning) => warning.category === 'ConfigurationWarning'
  );

  if (filteredWarnings.length === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: dimensions.standardMargin }}>
      <DataTable columns={columns} data={filteredWarnings} />
    </div>
  );
};

export default React.memo(SecosimResultWarnings);
